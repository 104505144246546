import { render, staticRenderFns } from "./Decline.vue?vue&type=template&id=6051e267&scoped=true&"
import script from "./Decline.vue?vue&type=script&lang=js&"
export * from "./Decline.vue?vue&type=script&lang=js&"
import style0 from "./Decline.vue?vue&type=style&index=0&id=6051e267&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6051e267",
  null
  
)

export default component.exports